<template>
    <div>
        <ts-page-title
            :title="$t('shop.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                { text: $t('shop.pageTitle'), href: '/admin/agency/shops' },
                {
                    text: $t('shop.viewMap'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <ts-loading-banner :loading="loading">
                    <div style="height: 80vh;" class="tw-relative">
                        <gmap-map
                            :center="center"
                            :zoom="zoom"
                            style="width: 100%; height: 100%"
                            ref="gmap"
                        >
                            <gmap-info-window
                                v-for="win in resources"
                                :key="win.shop_code"
                                :options="{
                                    maxWidth: 400,
                                    pixelOffset: { width: 0, height: -23 }
                                }"
                                :position="{
                                    lat: parseFloat(win.lat),
                                    lng: parseFloat(win.lng)
                                }"
                                :opened="win.isOpen"
                            >
                                <div
                                    class="tw-relative"
                                    style="min-width: 150px;"
                                >
                                    <b class="tw-capitalize">{{
                                        win.shop_name
                                    }}</b>
                                    <div class="tw-mt-2">
                                        <p class="tw-mb-0">
                                            {{ win.phone_number }}
                                        </p>
                                        <p class="tw-mb-0">
                                            {{ win.location_name }}
                                        </p>
                                    </div>
                                    <button
                                        class="tw-absolute tw-top-0 tw-right-0 tw-w-4 tw-h-4 tw-flex tw-justify-center tw-items-center hover:tw-text-gray-300"
                                        @click.prevent="onCloseWindowInfo(win)"
                                    >
                                        <i class="far fa-times-circle"></i>
                                    </button>
                                </div>
                            </gmap-info-window>

                            <gmap-marker
                                v-for="(s, index) in resources"
                                :key="index"
                                :position="{
                                    lat: parseFloat(s.lat),
                                    lng: parseFloat(s.lng)
                                }"
                                :icon="{
                                    url: icon,
                                    scaledSize: { width: 23, height: 23 },
                                    labelOrigin: {
                                        x: 30,
                                        y: -2
                                    }
                                }"
                                @click="onMarkerClick(s)"
                            />
                        </gmap-map>

                        <div
                            class="tw-w-60 tw-absolute tw-top-0 tw-right-0 tw-p-2 tw-overflow-y-scroll tw-backdrop-filter tw-backdrop-blur-xl"
                            style="height: 100%;"
                        >
                            <input
                                v-model.trim="search"
                                type="text"
                                class="form-control"
                                :placeholder="$t('shop.name')"
                            />
                            <hr />
                            <div class="tw-space-y-2">
                                <div
                                    class="tw-border tw-border-gray-400 tw-p-2 tw-rounded tw-cursor-pointer hover:tw-bg-gray-100 tw-duration-200"
                                    v-for="(shop, index) in shopList"
                                    :key="index"
                                    @click.prevent="onShopClickView(shop)"
                                >
                                    <p class="tw-mb-0">{{ shop.shop_name }}</p>
                                    <p class="tw-mb-0">
                                        {{ shop.phone_number }}
                                    </p>
                                    <p class="tw-mb-0">
                                        {{ shop.location_name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ts-loading-banner>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>
<!-- viewOnMap -->
<script>
import icon from "@/assets/shop_image.png";

export default {
    name: "ShopViewMap",
    data() {
        return {
            icon,
            loading: false,
            zoom: 13,
            center: { lat: 11.556178, lng: 104.928399 },
            resources: [],
            search: ""
        };
    },
    computed: {
        shopList() {
            return this.resources.filter(shop => {
                return shop.shop_name
                    .toLowerCase()
                    .includes(this.search.toLowerCase());
            });
        }
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loading = true;
            this.$store
                .dispatch("agency/shop/viewOnMap")
                .then(response => {
                    this.resources = response.data.map(el => ({
                        ...el,
                        isOpen: false
                    }));
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => (this.loading = false));
        },
        onMarkerClick(marker) {
            marker.isOpen = !marker.isOpen;
        },
        onCloseWindowInfo(marker) {
            marker.isOpen = !marker.isOpen;
        },
        onShopClickView(record) {
            if ((record.lat !== null) & (record.lng !== null)) {
                this.onCloseWindowInfo(record);
                this.$refs.gmap.$mapPromise.then(map => {
                    map.panTo({
                        lat: parseFloat(record.lat),
                        lng: parseFloat(record.lng)
                    });
                    this.zoom = 20;
                });
            }
        }
    }
};
</script>
<style>
.gm-ui-hover-effect {
    opacity: 0.6;
    display: none !important;
}
</style>
